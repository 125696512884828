import React, { useEffect, useRef, useState } from 'react';

import { useActivePath } from '../../hooks';
import KafkaLanding from './kafka';
import VectorDBLanding from './vectordb';
import AnimatedArrow from '../AnimatedArrow';
import Carousel, { SlideDirection } from '../Carousel';
import AiLab from './AiLab';
import AIOps from './AiOps';

interface ProductLandingProps {
  product?: string;
}

const ProductLanding = ({ product }: ProductLandingProps) => {
  const products = {
    vectordb: <VectorDBLanding />,
    kafka: <KafkaLanding />,
    aiLab: <AiLab />,
    aiOps: <AIOps />,
  };
  const ref = useRef<HTMLElement>(null);
  useActivePath('/#products', ref);

  const getIndex = () => {
    return Object.keys(products).indexOf(product || 'vectordb');
  };

  return (
    <section className="section scroll-mt-16" id="products" ref={ref}>
      <Carousel slides={Object.values(products)} moveTo={getIndex()} />
    </section>
  );
};

export default ProductLanding;
