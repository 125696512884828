import React from 'react';
import LeftArrowIcon from '../../icons/left-arrow.svg';
import RightArrowIcon from '../../icons/right-arrow.svg';

interface AnimatedArrowProps {
  direction?: 'left' | 'right';
  onClick?: () => void;
}

const AnimatedArrow = (props: AnimatedArrowProps) => {
  const direction = props.direction || 'left';
  const delayTimers = {
    right: ['0ms', '600ms', '1200ms'],
    left: ['1200ms', '600ms', '0ms'],
  };

  const opacity = {
    left: ['opacity-10', 'opacity-25', 'opacity-50'],
    right: ['opacity-50', 'opacity-25', 'opacity-10'],
  };

  const translateMap = {
    left: ['translate-x-[8px]', 'translate-x-[4px]', 'translate-x-[0]'],
    right: ['translate-x-[0]', 'translate-x-[-4px]', 'translate-x-[-8px]'],
  };

  const Component = props.direction === 'left' ? LeftArrowIcon : RightArrowIcon;

  return (
    <button
      className="flex lg:*:animate-none lg:*:hover:animate-fade-in-out *:animate-fade-in-out"
      onClick={props.onClick}
    >
      <Component
        className={`h-[1.2rem] md:h-[1.5rem] ${translateMap[direction][0]} ${opacity[direction][1]} text-foreground ${delayTimers[direction][1]}`}
        style={{ animationDelay: delayTimers[direction][0] }}
      />
      <Component
        className={`h-[1.2rem] md:h-[1.5rem] ${translateMap[direction][1]} ${opacity[direction][1]} text-foreground ${delayTimers[direction][1]}`}
        style={{ animationDelay: delayTimers[direction][1] }}
      />
      <Component
        className={` h-[1.2rem] ${opacity[direction][2]} ${translateMap[direction][2]} md:h-[1.5rem] text-foreground ${delayTimers[direction][2]}`}
        style={{ animationDelay: delayTimers[direction][2] }}
      />
    </button>
  );
};

export default AnimatedArrow;
