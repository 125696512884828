import React from 'react';
import CTA from '../CTA';

const AiLab = () => {
  return (
    <div>
      <h1 className="text-center">
        Bitnimbus AI/ML Lab: Experiment and Build with AI Rapidly, Your Way
      </h1>

      <div className="mt-8 block lg:grid lg:grid-cols-2 items-start">
        <div>
          <img
            src={
              'https://bitnimbus-public-assets.us-east-1.linodeobjects.com/static/logo/bitnimbus_aiml_lab.png'
            }
            className="rounded-lg md:h-[360px] lg:w-[100%] lg:h-[100%] m-auto shadow-md"
            alt="AI/ML Lab"
          />
        </div>
        <div className="lg:ml-6 lg:mt-0 mt-4">
          <p>
            All AI journeys start with evaluating the efficacy of AI/ML models.
            The&nbsp; Bitnimbus AI/ML Lab is a powerful, secure, and
            collaborative environment designed for businesses to evaluate and
            experiment with a variety of open-source and private Large Language
            Models (LLMs). Whether you’re testing foundation models, fine-tuning
            AI/ML with proprietary data, or assessing AI’s impact on your
            business operations, our AI/ML Lab enables seamless iteration and
            model assessment—tailored to your unique business context. You get
            tools to compare models based on accuracy, cost, and efficiency, all
            while keeping your data secure.
          </p>
          <ul className="mt-4 list-disc mx-[5%]">
            <li>Spin up a secure lab environment with simple 1-click </li>
            <li>Access and compare open-source and proprietary LLMs</li>
            <li>Fine-tune models with your own data for enhanced relevance</li>
            <li>
              Perform controlled AI/ML experiments to measure business impact
            </li>
            <li>
              Ensure AI adoption aligns with your security, privacy, and
              governance policies
            </li>
            <li>
              Get the best value out of your AI inference using the most
              efficient GPUs
            </li>
          </ul>
          <div className="flex space-x-4 mt-4 md:mt-6 justify-end">
            <CTA
              type="default"
              label="Schedule Demo >"
              external={false}
              link="#contact-us"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AiLab;
