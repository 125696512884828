import React from 'react';
import CTA from '../CTA';

const AIOps = () => {
  return (
    <div>
      <h1 className="text-center">
        Bitnimbus AI/ML Ops: Multi-Cloud AI/ML Hosting & Management
      </h1>
      <div className="mt-8 block lg:grid lg:grid-cols-2 items-start">
        <div>
          <img
            src={
              'https://bitnimbus-public-assets.us-east-1.linodeobjects.com/static/logo/bitnimbus_aiml_ops.png'
            }
            className="rounded-lg md:h-[360px] h-[240px] lg:w-[100%] lg:h-[100%] w-[auto] m-auto shadow-md"
            alt="deploy-managed-vectordb"
          />
        </div>
        <div className="lg:ml-6 lg:mt-0 mt-4">
          <p>
            When your AI initiatives are ready for production, Bitnimbus AI/ML
            Ops provides a secure, scalable, and multi-cloud environment to
            deploy and manage AL/ML agents. The avoidance of vendor lock-in is
            crucial for long-term flexibility and cost management. Our fully
            managed service ensures your AI/ML applications run securely,
            reliably, and cost-efficiently, no matter where you operate.
          </p>
          <ul className="mt-4 list-disc ml-[5%]">
            <li>
              Deploy AI/ML agents across Akamai, Hezner, AWS, and private cloud
              environments
            </li>
            <li>LLM Agnostic - switch between LLM models in your apps</li>
            <li>
              Monitor and optimize AI/ML performance in real-world applications
            </li>
            <li>
              Automate compliance and governance for secure AI/ML deployments
            </li>
            <li>Scale effortlessly to meet growing AI/ML workloads</li>
          </ul>
          <div className="flex space-x-4 mt-4 md:mt-6 justify-end">
            <CTA
              type="default"
              label="Schedule Demo >"
              external={false}
              link="#contact-us"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AIOps;
