import React, { useRef } from 'react';

import { useActivePath, useSiteMetadata } from '../../hooks';
import CTA from '../CTA';

const Home = () => {
  const ref = useRef(null);
  useActivePath('#home', ref);
  const data = useSiteMetadata();

  const isHevcSupported = () => {
    if (typeof window !== 'undefined') {
      const isMac = window.navigator.platform.indexOf('Mac') >= 0;
      const isIpad = window.navigator.platform.indexOf('iPad') >= 0;
      const isIphone = window.navigator.platform.indexOf('iPhone') >= 0;
      const isIPod = window.navigator.platform.indexOf('iPod') >= 0;
      return (
        isMac ||
        isIpad ||
        isIphone ||
        isIPod ||
        (window.navigator.userAgent.includes('Macintosh') &&
          window.navigator.userAgent.indexOf('Firefox') < 0)
      );
    }
    return false;
  };

  return (
    <section
      id="home"
      className="section block space-y-6 lg:space-y-0 lg:flex items-center justify-center flex-row-reverse scroll-mt-16"
    >
      <div className="lg:mx-[-1rem]">
        <video
          className="lg:w-[100%] w-[75%] mx-auto h-auto"
          loop
          autoPlay
          muted
        >
          {isHevcSupported() ? (
            <source
              src="https://bitnimbus-public-assets.us-east-1.linodeobjects.com/static/images/mutlicloud.mov"
              type="video/mp4"
            />
          ) : (
            <source
              src="https://bitnimbus-public-assets.us-east-1.linodeobjects.com/static/images/multicloud.webm"
              type="video/webm"
            />
          )}
        </video>
      </div>
      <div ref={ref}>
        <div>
          <h1 className="text-center lg:text-left text-balance">
            Multi Cloud Data Platform For All Developers.
          </h1>
          <h2 className="tagline my-1 text-center lg:text-left">
            You Click, We Deploy.
          </h2>
          <span className="flex flex-wrap mt-3 md:mt-6 justify-center lg:justify-start">
            <CTA
              type="primary"
              label="Sign Up for free"
              external={true}
              link={data.site.siteMetadata.productLink}
            />
            <span className="ml-4">
              <CTA
                type="default"
                label="Check Pricing >"
                external={false}
                link="/pricing"
              />
            </span>
          </span>
        </div>
      </div>
    </section>
  );
};

export default Home;
