import React from 'react';
import VectorDBImage from '../../images/vectordb.png';
import CTA from '../CTA';
import { useSiteMetadata } from '../../hooks';
import AnimatedArrow from '../AnimatedArrow';

const VectorDBLanding = () => {
  const data = useSiteMetadata();

  return (
    <div>
      <h1 className="lg:hidden block text-center">VectorDB</h1>
      <div className="mt-8 block lg:grid lg:grid-cols-2 items-start">
        <div>
          <img
            src={
              'https://bitnimbus-public-assets.us-east-1.linodeobjects.com/static/logo/bitnimbus_vectordb_white.png'
            }
            className="rounded-lg lg:h-[100%] lg:w-[100%] md:h-[360px] h-[240px] w-[auto] m-auto shadow-md"
            alt="deploy-managed-vectordb"
          />
        </div>
        <div className="lg:ml-6 lg:mt-0">
          <h1 className="hidden lg:block">VectorDB</h1>

          <div className="mt-6">
            <p>
              We're excited to introduce Bitnimbus VectorDB!
              <br />
              <br />
              Supercharge your AI applications with valuable user context to
              produce actionable insights, generate accurate summarizations,
              drive intelligent chat support systems, and more. Powered by
              Chroma, a lightweight yet robust open-source technology for
              storing, manipulating, and retrieving unstructured data—our fully
              managed solution takes the complexity out of it, helping you
              unlock the full potential of large language models securely and
              effortlessly.
              <br />
              <br />
              Start using our managed VectorDB today!
            </p>
            <div className="flex space-x-4 mt-4 md:mt-6">
              <div>
                <CTA
                  type="primary"
                  label="Free Trial"
                  external={true}
                  link={data.site.siteMetadata.productLink}
                />
              </div>
              <CTA
                type="default"
                label="Schedule Demo >"
                external={false}
                link="#contact-us"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VectorDBLanding;
