import React, { useEffect, useState } from 'react';
import AnimatedArrow from '../AnimatedArrow';

export enum SlideDirection {
  TO_RIGHT,
  TO_LEFT,
}

export interface CarouselProps {
  slides: React.ReactNode[];
  moveTo: SlideDirection;
}

const Carousel = (props: CarouselProps) => {
  const [currentSlide, setCurrentSlide] = useState(props.moveTo || 0);
  const handleSlide = (toIndex: number) => {
    if (toIndex >= props.slides.length || toIndex < 0) {
      return;
    }
    setCurrentSlide(toIndex % props.slides.length);
  };

  useEffect(() => {
    if (props.moveTo >= 0) {
      setCurrentSlide(props.moveTo);
    }
  }, [props.moveTo]);

  return (
    <div className=" overflow-x-hidden max-w-[100%]">
      <div
        className={`flex flex-shrink-0 transition-all duration-500 ease-in-out`}
        style={{
          width: `${props.slides.length * 100}%`,
          transform: `translateX(calc(-${currentSlide} * 100% / ${props.slides.length})`,
        }}
      >
        {props.slides.map((slide, index) => {
          return (
            <div className="w-[100%] grid grid-flow-col-dense gap-2">
              <div
                className={`self-center ${index === currentSlide && index !== 0 ? 'opacity-100' : 'opacity-0'}`}
              >
                <AnimatedArrow
                  direction="left"
                  onClick={() => handleSlide(index - 1)}
                />
              </div>

              <div className="self-center">{slide}</div>
              <div
                className={`self-center mt-0 ${index === currentSlide && index !== props.slides.length - 1 ? 'opacity-100' : 'opacity-0'}`}
              >
                <AnimatedArrow
                  direction="right"
                  onClick={() => handleSlide(index + 1)}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Carousel;
