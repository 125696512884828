import React, { useRef } from 'react';

import CTA from '../CTA';
import productGif from '../../images/deploy-kafka.avif';
import { useSiteMetadata } from '../../hooks';

const KafkaLanding = () => {
  const data = useSiteMetadata();
  return (
    <div>
      <h1 className="text-center">
        Spin Up Kafka Instances In Minutes For Free
      </h1>
      <div className="mt-8 block lg:flex lg:justify-between items-start flex-row-reverse">
        <div>
          <img
            src={productGif}
            className="rounded-lg md:w-[640px] w-[360px] lg:w-auto m-auto shadow-md"
            alt="deploy-managed-kafka"
            height="100%"
            width="auto"
          />
        </div>
        <div className="lg:mr-6 lg:w-[75%] lg:mt-0 mt-6">
          <p>
            Unlock the full potential of Kafka—one of the most versatile event
            streaming platforms—through our fully managed solution at
            Bitnimbus.io. Experience our fast, secure, and affordable data
            streaming service ready in just a few clicks.
            <br />
            <br />
            Sign up today, no credit card required.
          </p>
          <div>
            <ul className="list-none list-image-checkmark list-inside mt-6 space-y-2">
              <li>Choose the size that suits your budget and product needs</li>
              <li>Deploy your instances with a click of button</li>
              <li>Easy to navigate and to manage all your workloads</li>
              <li>
                24x7 support to ensure your workloads is always up and running
              </li>
            </ul>
          </div>
          <div className="flex space-x-4 mt-4 md:mt-6">
            <div>
              <CTA
                type="primary"
                label="Free Trial"
                external={true}
                link={data.site.siteMetadata.productLink}
              />
            </div>
            <CTA
              type="default"
              label="Schedule Demo >"
              external={false}
              link="#contact-us"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default KafkaLanding;
